import React from "react"
import { Link } from "gatsby"
import Loadable from "@loadable/component"
import Breadcrumbs from "~/ui/compositions/navigation/breadcrumb"
import Page from "~/models/page"
import Article from "~/models/article"

import SEO from "~/ui/helpers/seo"
import formatdate from "~/helpers/formatdate"
import Frame from "~/ui/layout/frame"
import MarkdownBlock from "../compositions/generic/markdown"
import ArticlesBlock from "~/ui/compositions/article/list"
import Image from "../elements/image/image"

import { graphql } from "gatsby"
import {
  ArticlePageQuery,
  ContentfulPage,
  ContentfulArticle,
} from "../../../types/graphql-types"

export const query = graphql`
  query ArticlePage($id: String, $slug: String) {
    contentfulPage(id: { eq: $id }) {
      ...PageFields
    }
    contentfulArticle(slug: { eq: $slug }) {
      ...ArticleFields
    }
    allContentfulArticle(limit: 3) {
      nodes {
        ...ArticleFields
      }
    }
  }
`

type Props = {
  data: ArticlePageQuery
}

const ArticlePage = ({ data }: Props) => {
  const Analytics = Loadable(() => import("../helpers/analytics"))

  const contentfulPage = data.contentfulPage as ContentfulPage
  const page = new Page(contentfulPage)
  const contentfulArticle = data.contentfulArticle as ContentfulArticle
  const article = new Article(contentfulArticle)
  const articles = data.allContentfulArticle.nodes.map(article => {
    return new Article(article as ContentfulArticle)
  })

  return (
    <Frame>
      <Analytics page={page} />
      <SEO page={page} />
      <React.Fragment>
        <section>
          <div className="max-w-screen-xl mx-auto pt-6 lg:pt-10 px-4 md:px-10 xl:px-20">
            <h1 className="mb-3 lg:mb-6 text-3xl lg:text-6xl font-bold text-font-1 tracking-[-0.025em]">
              {article.title}
            </h1>

            <div className="mb-6 lg:mb-9 flex gap">
              <span className="text-sm lg:text-base text-font-2 mr-1">
                Published on:
              </span>
              <span className="text-sm lg:text-base text-font-1">
                {formatdate(article.createdAt)}
              </span>
            </div>

            <Image
              className="block mb-10"
              gatsbyImageData={article.page.image}
              alt={article.title}
            />
            <div className="mb-6">
              <div className="relative justify-between md:flex items-center max-w-screen-xl mx-auto py-7 lg:py-0">
                <Breadcrumbs breadcrumbs={page.breadcrumbs} />
              </div>
            </div>
            <div className="flex gap-3 lg:gap-4 items-center">
              <Image
                className="block w-[42px] h-[42px] lg:w-[56px] lg:h-[56px] rounded-full z-10"
                gatsbyImageData={article.author.avatar}
                alt={article.author.name}
              />
              <div>
                <span className="mr-1 text-sm lg:text-2xl text-font-2">By</span>
                <span className="text-sm lg:text-2xl text-font-1">
                  {article.author.name}
                </span>
              </div>
            </div>
          </div>
        </section>

        {page.blocks.map(block => (
          <React.Fragment key={block.id}>
            {block.name == "Markdown" && (
              <MarkdownBlock block={block} {...block.props}>
                {block.content.childMdx?.body}
              </MarkdownBlock>
            )}

            {block.name == "Author" && (
              <div className="max-w-[738px] bg-gray-200 rounded-3xl p-6 mx-auto mb-6">
                <Image
                  className="block w-[64px] h-[64px] rounded-full z-10 mb-4 mx-auto"
                  gatsbyImageData={article.author.avatar}
                  alt={article.author.name}
                />

                <Link
                  to={`/about-us/${article.author.slug}`}
                  className="block text-lg text-font-1 mb-4 mx-auto font-bold text-center"
                >
                  {article.author.name}
                </Link>

                <p className="text-lg mx-auto text-center max-w-[460px]">
                  {article.author.description}
                </p>
              </div>
            )}

            {block.name == "Warning" && (
              <div className="max-w-[738px] mx-auto bg-yellow-300 rounded-3xl px-12 py-4 mb-20">
                <div className="mb-2 flex gap-2 items-center">
                  <img
                    src="/images/general/information-circle.svg"
                    alt=""
                    className="w-6"
                    decoding="async"
                  />
                  <div className="text-lg text-font-1 font-bold">
                    Stay in control
                  </div>
                </div>

                <p className="text-lg text-font-1">
                  If you need help staying in control, Gambling.IN advocates
                  responsible gambling. Gambling Therapy is a global online
                  service for those worried about their own gambling or the
                  gambling of others. You can email support@gamblingtherapy.org
                  or live chat online at gamblingtherapy.org to receive
                  immediate support.
                </p>
              </div>
            )}

            {block.name == "Articles" && (
              <ArticlesBlock articles={articles} {...block.props} />
            )}
          </React.Fragment>
        ))}
      </React.Fragment>
    </Frame>
  )
}

export default ArticlePage
